<template>
  <div style="background: #fff;color: #000;height: 100%;">

  </div>
</template>
  
<script>
import { ImagePreview } from "vant";
export default {
  //   mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      img: this.$route.query.img,
    };
  },
  methods: {
    back() {
      parent.WeixinJSBridge.call("closeWindow");
    },
  },
  created() {
    var _this = this;
    var img = this.$route.query.img.split(',');
    var idx = this.$route.query.idx
    ImagePreview({
      images: img,
      asyncClose: true,
      // closeable: true,
      startPosition:idx,
      onClose() {
        close();
        // _this.$router.go(-1);
      },
    });
    // this.onLoad();
  },
  mounted() {},
  computed: {},
};
</script>


  
  